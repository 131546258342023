import React from 'react';
import { Space } from 'antd';
import { DateRangeTabs } from './DateRangeTabs';
import { TimezoneSelector } from '../TimezoneSelector';
import { Moment } from 'moment';

interface Props {
  dateRangeStart: Moment;
  dateRangeEnd: Moment;
  onSelectDateRange: (range: any) => void;
  onSelectTimezone: (timezone: string) => void;
}

export const CreatedAtDateSearchByInput = (props: Props) => {
  return (
    <Space style={{ margin: '10px' }}>
      <DateRangeTabs {...props}></DateRangeTabs>
      <TimezoneSelector onChange={props.onSelectTimezone} />
    </Space>
  );
};

import React from 'react';
import { Space, Row, Col } from 'antd';

import { MerchantSlugSelector } from '../MerchantSlugSelector';
import { DspSelector } from '../DspSelector';
import { DateRangeTabs } from './DateRangeTabs';
import { Moment } from 'moment';

interface Props {
  dateRangeStart: Moment;
  dateRangeEnd: Moment;
  onSelectDateRange: (range: any) => void;
  onSelectMerchant: (selectedMerchantSlugs: string[]) => void;
  onSelectDsp: (dspId: string) => void;
}

export const InCustodyDateSearchByInput = (props: Props) => {
  return (
    <Space style={{ margin: '10px' }}>
      <DateRangeTabs {...props}></DateRangeTabs>
      <MerchantSlugSelector onChange={props.onSelectMerchant} />
      <DspSelector onChange={props.onSelectDsp} />
    </Space>
  );
};

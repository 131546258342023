import React from 'react';
import { Row, Input } from 'antd';
import { FuzzySearchBar } from '../FuzzySearchBar';

interface Props {
  onSeletLabel: (labelId: string) => Promise<void>;
}

export const ClientDetailsSearchByInput = (props: Props) => {

  return (
    <Row style={{ margin: '10px', padding: '0' , width: '50%' }}>
      <FuzzySearchBar onSelectLabel={props.onSeletLabel}/>
    </Row>
  );
};

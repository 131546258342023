import React, { useCallback, useState } from 'react';
import { Select, Space } from 'antd';

interface Props {
  /** Function that gets executed when the Selector changes */
  onChange: (filterOption: FilterOption) => void;
}

export enum FilterOption {
  createdAt = 'Label created date',
  trackingIds = 'Multiple tracking ids',
  clientDetails = 'Client details',
  inCustody = 'Package est. in Custody date',
  trackingEvent = 'Tracking event update',
}

export const SearchBySelector = ({ onChange }: Props) => {
  const [filterOption, setFilteredOption] = useState<FilterOption>(FilterOption.createdAt);

  const handleSelectFilter = useCallback(
    (value: FilterOption) => {
      setFilteredOption(value);
      onChange(value);
    },
    [filterOption]
  );

  return (
    <>
      <Space direction="horizontal" size="middle">
        Search By:
        <Select
          placeholder="Filter By"
          value={filterOption}
          style={{ width: 250 }}
          options={[...Object.values(FilterOption)].map((opt) => {
            return { label: opt, value: opt };
          })}
          onChange={handleSelectFilter}
        />
      </Space>
    </>
  );
};

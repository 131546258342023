import React, { useState } from 'react';
import { LabelStateTag } from '../helpers/LabelHelpers';
import { Modal, Space, Statistic, Button } from 'antd';
import { blue } from '@ant-design/colors';
import { EyeOutlined } from '@ant-design/icons';

interface Props {
  title: string;
  labelMapper: Record<string, number>;
}

export const LabelStatsModal = (props: Props) => {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <Button onClick={() => setVisible(true)} icon={<EyeOutlined />}>
        {props.title}
      </Button>

      <Modal open={visible} footer={null} closable={false} onCancel={() => setVisible(false)}>
        <Space style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {Object.keys(props.labelMapper)
            .filter((m) => props.labelMapper[m])
            .sort((c, i) => (props.labelMapper[c] > props.labelMapper[i] ? -1 : 1))
            .map((m) => (
              <Statistic
                style={{ marginRight: '20px' }}
                key={m}
                title={<LabelStateTag state={m as any} style={{fontSize: '14px' }}/>}
                valueStyle={{ color: blue.primary, fontSize: '20px' }}
                value={props.labelMapper[m]}
              />
            ))}
        </Space>
      </Modal>
    </div>
  );
};

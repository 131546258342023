import React from 'react';
import { DatePicker, Space } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

interface Props {
  onSelectDateRange: (range: any) => void;
  dateRangeStart: moment.Moment;
  dateRangeEnd: moment.Moment;
}

export const DateRangeTabs = (props: Props) => {
  return (
    <Space>
      <RangePicker
        value={[props.dateRangeStart, props.dateRangeEnd]}
        format="MMMM Do, YYYY"
        renderExtraFooter={() => (
          <div>
            <button onClick={() => props.onSelectDateRange([moment().startOf('day'), moment().endOf('day')])}>
              Today
            </button>
            <button onClick={() => props.onSelectDateRange([moment().subtract(7, 'days'), moment()])}>
              Last 7 Days
            </button>
            <button onClick={() => props.onSelectDateRange([moment().subtract(30, 'days'), moment()])}>
              Last 30 Days
            </button>
          </div>
        )}
        onCalendarChange={props.onSelectDateRange}
        allowClear
        style={{ width: 350 }} 
      />
    </Space>
  );
};

import { IDeliveryServicePartner, ILabelV2 } from '@swyft/swyft-common';
import * as _ from 'lodash';
import moment from 'moment';
import { Timestamp } from '@firebase/firestore-types';
import { getZipCode } from '../services/firestore';
import { getLabelPostalZone } from '../helpers/LabelHelpers';

const CSV_EXPORT_COLUMNS = [
  'merchantSlug',
  'businessName',
  'trackingNumber',
  'state',

  'dspId',
  'market',
  'subMarket',
  // 'dateOfState',
  'createdAt',
  'updatedAt',
  'originAddressLine1',
  'originAddressLine2',
  'originPostalCode',
  'originCity',
  'originProvince',
  'originCountry',
  'recipientName',
  'recipientEmail',
  'recipientPhone',
  'destinationAddressLine1',
  'destinationAddressLine2',
  'destinationPostalCode',
  'destinationCity',
  'destinationProvince',
  'destinationCountry',
  'notes',
  // 'packageType',
  'serviceType',
  // 'length',
  // 'width',
  // 'height',
  // 'weight',

  // 'barcodeData',
  // 'lastestDeliveryFailureReason',
  // 'deliveryAttemptCount',
  // ...Array.from({ length: 3 })
  //   .map((c, i) => {
  //     return [`delivery_attempt_${i + 1}_rate`];
  //   })
  //   .reduce((c, i) => {
  //     return [...c, ...i];
  //   }),
  // ...Array.from({ length: 10 })
  //   .map((c, i) => {
  //     return [
  //       `tracking_event_type_${i + 1}`,
  //       `tracking_event_date_${i + 1}`,
  //       `tracking_event_dsp_${i + 1}`,
  //     ];
  //   })
  //   .reduce((c, i) => {
  //     return [...c, ...i];
  //   }),
];

const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Download an array of labels rendered as a CSV.
 *
 * @param fileName - Name of the file to be downloaded.
 * @param labels - Array of labels to be rendered.
 */
export const exportLabelsCSV = async (
  fileName: string,
  labels: ILabelV2[],
  dsps: IDeliveryServicePartner[],
  onDone: () => void,
  onError: () => void
) => {
  try {
    const labelChunks = _.chunk(labels, 1000);

    let formattedLabels = [];

    for (const chunk of labelChunks) {
      const chunkFormattedLabels = await Promise.all(
        chunk.map(async (label, index) => {
          if (index % 1000 === 0) {
            //
            await delay(1000);
          }

          // const deliveryAttempts = await getDeliveryAttempts(label.merchantId, label.id, 'asc');

          // const formattedDeliveryAttempts = deliveryAttempts
          //   ?.map((d, i) => {
          //     return {
          //       [`delivery_attempt_${i + 1}_rate`]: d.deliveryRate.deliveryPrice
          //         ? d.deliveryRate.deliveryPrice / 100
          //         : undefined,
          //     };
          //   })
          //   ?.reduce((c, i) => {
          //     return { ...c, ...i };
          //   }, {});

          // const trackingEvents = await getTrackingEvents(label.merchantId, label.id);

          // const formattedTrackingEvents = trackingEvents
          //   ?.sort(
          //     (a, b) => (a.timestamp as Timestamp).seconds - (b.timestamp as Timestamp).seconds
          //   )
          //   .map((t, i) => {
          //     return {
          //       [`tracking_event_type_${i + 1}`]: t.state,
          //       [`tracking_event_date_${i + 1}`]: parseTimestamp(
          //         t.timestamp as Timestamp,
          //         'yyyy-MM-DD HH:mm:ssZ'
          //       ),
          //       [`tracking_event_dsp_${i + 1}`]: t.dspId || '-',
          //     };
          //   })
          //   .reduce((c, i) => {
          //     return { ...c, ...i };
          //   }, {});

          const zipCode = await getZipCode(getLabelPostalZone(label) || '');

          const csvContent = {
            merchantSlug: label.merchantSlug,
            businessName: label.businessName, // label.businessName,
            trackingNumber: label.trackingNumber,
            state: label.state,
            dspId: label.dspId,
            market: zipCode.marketId || '',
            subMarket: zipCode.subMarketId || '',
            createdAt: moment((label.createdAt as Timestamp).toDate()).format(),
            updatedAt: moment((label.updatedAt as Timestamp).toDate()).format(),
            originAddressLine1: label.origin.address.line1,
            originAddressLine2: label.origin.address.line2,
            originPostalCode: label.origin.address.postalCode,
            originCity: label.origin.address.city,
            originProvince: label.origin.address.province,
            originCountry: label.origin.address.country,
            recipientName: `${label.destination.firstName}${
              label.destination.lastName ? ' ' + label.destination.lastName : ''
            }`,
            recipientEmail: label.destination.email,
            recipientPhone: label.destination.phone,
            destinationAddressLine1: `${label.destination.validatedAddressV2?.buildingNumber} ${label.destination.validatedAddressV2?.street}`,
            destinationAddressLine2: label.destination.validatedAddressV2?.line2,
            destinationPostalCode: label.destination.validatedAddressV2?.postalCode,
            destinationCity: label.destination.validatedAddressV2?.city,
            destinationProvince: label.destination.validatedAddressV2?.province,

            destinationCountry: label.destination.address.country,
            notes: label.notes,
            serviceType: label.serviceType,

            // length: label.dims?.length,
            // width: label.dims?.width,
            // height: label.dims?.height,
            // weight: label.dims?.weight,

            // packageType: label.packageType,

            // if it is a previously failed delivery, we want to surface it
            // lastestDeliveryFailureReason: deliveryAttempts?.[0]?.errorCode || '',
            // deliveryAttemptCount: `${deliveryAttempts?.length || 0}`,
            // ...formattedDeliveryAttempts,
            // barcodeData: `sys:swyft&track:${label.trackingNumber}&org:${label.merchantSlug}`,
            // ...formattedTrackingEvents,
          };

          return csvContent;
        })
      );

      formattedLabels.push(...chunkFormattedLabels);

      delay(1000);
    }

    // Build header
    let csvContent = CSV_EXPORT_COLUMNS.join(',') + '\n';
    // Add the rows
    formattedLabels.forEach((label: any) => {
      csvContent +=
        CSV_EXPORT_COLUMNS.map((key) => (label[key] ? `"${label[key]}"` : '')).join(',') + '\n';
    });

    exportFile(fileName, csvContent, 'csv');

    onDone();
  } catch (err) {
    console.error('Error happened while trying to export labels to csv.', err);
    onError();
  }
};

/**
 * Download a file through the browser.
 *
 * @param fileName - Name of the file to be downloaded.
 * @param contents - Rendered content of the file.
 * @param fileType - File extension.
 * @param openNewTab - When set to true will attempt to open the file in a new tab.
 */
const exportFile = (
  fileName: string,
  contents: BlobPart,
  fileType: string,
  openNewTab: boolean = false
) => {
  const blob = new Blob([contents], { type: fileType } as BlobPropertyBag);
  const blobURL = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = blobURL;
  link.setAttribute('target', '_blank');
  link.download = fileName;

  if (openNewTab) {
    let newTab = window.open();
    if (newTab) {
      newTab.location.href = blobURL;
    }
  }
  link.click();
};

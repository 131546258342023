import React from 'react';
import { Row, Input } from 'antd';

interface Props {
  onSelectTrackingIds: (trackingIds: string[]) => void;
}

export const TrackingIdsSearchByInput = (props: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let searchInput = e.target.value.replaceAll('"', '');
    searchInput = searchInput.replaceAll(',', ' ');
    const trackingIds = searchInput.split(' ').map((trackingId) => trackingId.trim());

    const uniqueTrackingIds: string[] = [];
    for (let i = 0; i < trackingIds.length; i++) {
      const tId = trackingIds[i];

      if (uniqueTrackingIds.indexOf(tId) === -1) {
        uniqueTrackingIds.push(tId);
      }
    }

    props.onSelectTrackingIds(uniqueTrackingIds);
  };

  return (
    <Row style={{ margin: '10px', padding: '0', width: '40%' }}>
      <Input placeholder="Tracking Ids..." onChange={handleChange} />
    </Row>
  );
};

import React from 'react';
import {
  Button,
  Table,
  Space,
  Row,
  Col,
  Statistic,
  message,
  Modal,
  Tag,
  Select,
  Typography,
} from 'antd';
import {
  PrinterTwoTone,
  PrinterOutlined,
  FileSearchOutlined,
  FullscreenOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { blue } from '@ant-design/colors';
import {
  DATE_FORMAT,
  ILabelV2,
  ITrackingEvent,
  TrackingEventState,
  IDeliveryServicePartner,
  ILabelV3,
} from '@swyft/swyft-common';

import { uniq, capitalize, get, chunk } from 'lodash';
import moment, { Moment, unitOfTime } from 'moment';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DEFAULT_PAGINATION_CONFIG } from '../consts';
// import { EditLabelModal } from '../components/EditLabelModal';
import { LabelDetailsDrawer } from '../components/LabelDetailsDrawer';
import {
  getLabelId,
  LabelStateTag,
  getTrackingEventStateOptions,
  getLabelPostalZone,
  getDspName,
  GetDspNames,
} from '../helpers/LabelHelpers';

import { FUNCTIONS } from '../services/functions';
import {
  getLabelById,
  getLabelsByCreatedAtRangeAndSlugs,
  getLabelsForShipDateRangeAndSlugs,
  getLabelsForTrackingEventStatuses,
  getLabelsByTrackingIds,
  getTrackingEvents,
  getAllDeliveryServicePartners,
} from '../services/firestore';

import { DspSelector } from '../components/DspSelector';
import { SearchBySelector, FilterOption } from '../components/SearchBySelector';
import { timezoneMapping } from '../components/TimezoneSelector';
import { Timestamp } from '@firebase/firestore-types';
import { exportLabelsCSV } from '../helpers/fileExportHelpers';
import { TrackingEventSearchByInput } from '../components/searchByInputs/TrackingEventSearchByInput';
import { TrackingIdsSearchByInput } from '../components/searchByInputs/TrackingIdsSearchByInput';
import { InCustodyDateSearchByInput } from '../components/searchByInputs/InCustodyDateSearchByInput';
import { CreatedAtDateSearchByInput } from '../components/searchByInputs/CreatedAtDateSearchByInput';
import { LabelStatsModal } from '../components/LabelStatsModal';
import { ClientDetailsSearchByInput } from '../components/searchByInputs/ClientDetailsSearchByInput';

const { Text } = Typography;

const ASSIGN_TO_DSP_BATCH_COUNT = 20;
const MIN_ASSIGN_TO_DSP_BATCH = 1000;

interface UrlSearchParams {
  org: string;
  shipDate: string;
}

interface State {
  merchantSlugs: string[];
  selectedMerchantSlugs: string[];
  selectedDspId: string;
  selectedAssignedDspId: string;
  selectedTrackingEvents: string[];
  selectedTrackingIds: string[];
  timezone: string;
  filterOption: FilterOption;
  rescheduleShipDate: Moment;
  modifyLabelsState: TrackingEventState;
  dateRangeStart: Moment;
  dateRangeEnd: Moment;
  labelsDateStart: Moment;
  labelsDateEnd: Moment;
  isLoadingTable: boolean;
  isLoadingMerchantSlugs: boolean;
  isEditModalVisible: boolean;
  isLabelDetailsDrawerVisible: boolean;
  isPrintingLabels: boolean;
  isDownloadingCsv: boolean;
  isRescheduleLabelsModalVisible: boolean;
  isReschedulingLabels: boolean;
  isModifyingLabelsState: boolean;
  isModifyLabelsStateModalVisible: boolean;
  isAssigningToDsp: boolean;
  isAssignToDspModalVisible: boolean;
  labels: ILabelV2[];
  filteredLabels: ILabelV2[];
  selectedLabels: ILabelV2[];
  selectedLabel: ILabelV2;
  rescheduleLabelErrors: any[];
  filteredFields: any;
  dsps: IDeliveryServicePartner[];
}

class LabelsBase extends React.Component<RouteComponentProps<UrlSearchParams>, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      merchantSlugs: [],
      rescheduleLabelErrors: [],
      selectedLabels: [],
      labels: [],
      filteredLabels: [],
      selectedMerchantSlugs: [],
      selectedDspId: '',
      selectedAssignedDspId: '',
      selectedTrackingEvents: [],
      selectedTrackingIds: [],
      timezone: timezoneMapping.Los_Angeles,
      filterOption: FilterOption.createdAt,
      rescheduleShipDate: moment(),
      modifyLabelsState: TrackingEventState.PENDING,
      dateRangeStart: moment(),
      dateRangeEnd: moment(),
      labelsDateStart: moment(),
      labelsDateEnd: moment(),
      selectedLabel: {} as ILabelV2,
      filteredFields: {},
      isEditModalVisible: false,
      isLabelDetailsDrawerVisible: false,
      isPrintingLabels: false,
      isDownloadingCsv: false,
      isRescheduleLabelsModalVisible: false,
      isReschedulingLabels: false,
      isModifyingLabelsState: false,
      isModifyLabelsStateModalVisible: false,
      isAssigningToDsp: false,
      isAssignToDspModalVisible: false,
      isLoadingTable: false,
      isLoadingMerchantSlugs: false,
      dsps: [],
    };
  }

  async componentDidMount() {
    // Check for query params
    const queryParams = queryString.parse(location.search);
    const { org, shipDate } = queryParams;
    if (org && shipDate) {
      this.setState(
        {
          selectedMerchantSlugs: org.toString().split(','),
          dateRangeStart: moment(shipDate, 'YYYY-MMM-DD'),
          dateRangeEnd: moment(shipDate, 'YYYY-MMM-DD'),
        },
        this.handleSearch
      );
    } else if (get(this.props, ['location', 'state', 'labelIds'])) {
      this.handleStatePassedLabelIds();
    }
    this.getDsps();
  }

  setSelectedMerchantSlugs = (selectedMerchantSlugs: string[]) => {
    this.setState({ selectedMerchantSlugs });
  };

  setSelectedTrackingEvents = (selectedTrackingEvents: TrackingEventState[]) => {
    this.setState({ selectedTrackingEvents });
  };

  setSelectedDspId = (selectedDspId: string) => {
    this.setState({ selectedDspId });
  };

  setSelectedTrackingIds = (selectedTrackingIds: string[]) => {
    this.setState({ selectedTrackingIds });
  };

  setSelectedAssignedDspId = (selectedAssignedDspId: string) => {
    this.setState({ selectedAssignedDspId });
  };

  setSelectedSearchByOption = (filterOption: FilterOption) => {
    this.setState({ filterOption });
  };

  setTimezone = (timezone: string) => {
    this.setState({ timezone });
  };

  onSearchByOptionChange = (filterOption: FilterOption) => {
    this.setSelectedMerchantSlugs([]);
    this.setSelectedDspId('');
    this.setSelectedTrackingIds([]);
    this.setSelectedSearchByOption(filterOption);
  };

  getProblemTrackingEvents = async (label: ILabelV2): Promise<ITrackingEvent[]> => {
    let problemEvents = await getTrackingEvents(label.merchantId, label.id);
    problemEvents = problemEvents
      .filter((e) => e.state === TrackingEventState.PROBLEM)
      .sort((a, b) => ((a.timestamp as Timestamp) > (b.timestamp as Timestamp) ? 1 : -1));
    return problemEvents;
  };

  getDsps = async () => {
    try {
      const dsps = await getAllDeliveryServicePartners();
      this.setState({ dsps: dsps.sort((a, b) => (a.name > b.name ? 1 : -1)) });
    } catch (err) {
      console.error(err);
      message.error('An error occured when loading dsps! See console.');
    }
  };

  getLabelTableColumns = () => {
    const { filteredFields } = this.state;

    return [
      {
        title: 'Merchant',
        dataIndex: 'merchantSlug',
        key: 'merchantSlug',
        filteredValue: filteredFields.merchantSlug || null,
        // generating filter option list from the merchant slugs of the loaded labels
        filters: uniq(this.state.labels.map((label) => label.merchantSlug))
          .sort()
          .map((merchantSlug: string) => ({ text: merchantSlug, value: merchantSlug })),
        onFilter: (value: any, row: ILabelV2) => row.merchantSlug === value,
        filterSearch: true,
      },
      {
        title: 'TrackingId',
        dataIndex: ['trackingNumber'],
        key: 'trackingNumber',
      },
      {
        title: 'Status',
        dataIndex: 'state',
        key: 'state',
        render: (text: TrackingEventState) => <LabelStateTag state={text} />,
        filteredValue: filteredFields.state || null,
        filters: Object.keys(TrackingEventState).map((state) => ({
          text: state.replace('RETURNED_TO_SENDER', 'RETURNED'),
          value: state,
        })),
        onFilter: (value: any, row: ILabelV2) => row.state === value,
      },
      {
        title: 'Est. In Custody Date',
        dataIndex: ['shipDate'],
        key: 'shipDate',
      },
      {
        title: 'Received',
        dataIndex: 'received',
        key: 'received',
        render: (received: any) =>
          Boolean(received) ? <Tag color="green">True</Tag> : <Tag color="red">False</Tag>,
        filteredValue: filteredFields.received || null,
        filters: [
          { text: 'Received', value: true },
          { text: 'Not received', value: false },
        ],
        onFilter: (value: any, row: ILabelV2) => !!row.received === value,
      },
      {
        title: 'Expected Date',
        dataIndex: ['expectedDeliveryDate'],
        key: 'expectedDeliveryDate',
      },
      // {
      //   title: 'On Time',
      //   dataIndex: 'isDelayed',
      //   key: 'isDelayed',
      //   render: (isDelayed: any) =>
      //     Boolean(isDelayed) ? <Tag color="red">False</Tag> : <Tag color="green">True</Tag>,
      //   filteredValue: filteredFields.isDelayed || null,
      //   filters: [
      //     { text: 'On time', value: false },
      //     { text: 'Delayed', value: true },
      //   ],
      //   onFilter: (value: any, row: ILabelV2) => !!row.isDelayed === value,,
      // },
      {
        title: 'Postal Code',
        dataIndex: ['destination', 'validatedAddressV2', 'postalCode'],
        key: 'postalCode',
        filteredValue: filteredFields.postalCode || null,
        filters: uniq(this.state.labels.map(getLabelPostalZone))
          .sort()
          .map((postalZone: string) => ({
            text: postalZone,
            value: postalZone,
          })),
        onFilter: (value: any, row: ILabelV2) => getLabelPostalZone(row) === value,
        filterSearch: true,
      },
      {
        title: 'Assigned To',
        dataIndex: 'dspId',
        key: 'dspId',
        render: (label: string) => {
          if (label !== undefined) {
            return getDspName(label, this.state.dsps);
          }
        },
        filteredValue: filteredFields.dspId || null,
        filters: [{ text: 'Not Assigned', value: 'N/A' }].concat(
          uniq(this.state.dsps.filter((dsp) => dsp.name !== ''))
            .sort()
            .map((dsp: IDeliveryServicePartner) => ({ text: dsp.name, value: dsp.id }))
        ),
        onFilter: (value: any, row: ILabelV2) => {
          let dspId = 'N/A';
          if (row.dspId) {
            dspId = row.dspId;
          }
          return dspId === value;
        },
        filterSearch: true,
      },
      {
        title: 'Manifested To',
        dataIndex: 'dspManifestInformationArr',
        key: 'dspManifestInformationArr',
        render: (dspManifestInformationArr: { dspId: string }[]) => {
          if (dspManifestInformationArr !== undefined) {
            return (
              <GetDspNames
                value={dspManifestInformationArr.map((m) => m.dspId)}
                dsps={this.state.dsps}
              />
            );
          }
        },
        filteredValue:
          filteredFields.dspManifestInformationArr?.map((m: any) => {
            return m;
          }) || null,
        filters: uniq(this.state.dsps.filter((dsp) => dsp.name !== ''))
          .sort()
          .map((dsp: IDeliveryServicePartner) => ({ text: dsp.name, value: dsp.id })),
        onFilter: (value: any, row: ILabelV2) => {
          if (row.dspManifestInformationArr?.map((m) => m.dspId)?.includes(value)) return true;
          return false;
        },
        filterSearch: true,
      },
      {
        title: 'Scanned By',
        dataIndex: 'scannedBy',
        key: 'scannedBy',
        render: (label: string[]) => {
          if (label !== undefined) {
            return <GetDspNames value={label} dsps={this.state.dsps} />;
          }
        },
        filteredValue: filteredFields.scannedBy || null,
        filters: uniq(this.state.dsps.filter((dsp) => dsp.name !== ''))
          .sort()
          .map((dsp: IDeliveryServicePartner) => ({ text: dsp.name, value: dsp.id })),
        onFilter: (value: any, row: ILabelV2) => {
          if (row.scannedBy?.includes(value)) return true;
          return false;
        },
        filterSearch: true,
      },
      // {
      //   title: 'Service Type',
      //   dataIndex: 'serviceType',
      //   key: 'serviceType',
      //   filteredValue: filteredFields.serviceType || null,
      //   filters: uniq(this.state.labels.map((label) => capitalize(label.serviceType)))
      //     .sort()
      //     .map((serviceType: string) => ({ text: serviceType, value: serviceType })),
      //   onFilter: (value: any, row: ILabelV2) => capitalize(row.serviceType) === value,
      // },
      {
        title: 'City/Town',
        dataIndex: ['destination', 'address', 'city'],
        key: 'city',
        filteredValue: filteredFields.city || null,
        filters: uniq(this.state.labels.map((label) => capitalize(label.destination.address.city)))
          .sort()
          .map((city: string) => ({ text: city, value: city })),
        onFilter: (value: any, row: ILabelV2) => capitalize(row.destination.address.city) === value,
        filterSearch: true,
      },
      {
        title: 'State',
        dataIndex: ['destination', 'address', 'province'],
        key: 'state/Province',
        filteredValue: filteredFields['state/Province'] || null,
        filters: uniq(this.state.labels.map((label) => label.destination.address.province))
          .sort()
          .map((province: string) => ({ text: province, value: province })),
        onFilter: (value: any, row: ILabelV2) => row.destination.address.province === value,
        filterSearch: true,
      },
      {
        title: 'Action',
        key: 'delete',
        render: (row: ILabelV2) => (
          <Space size="middle">
            <Button
              type="default"
              size="small"
              icon={<PrinterTwoTone />}
              onClick={() => this.printLabels([row])}
            >
              Print
            </Button>
            <Button
              type="default"
              size="small"
              icon={<FullscreenOutlined />}
              onClick={() => this.showLabelDetailsDrawer(row)}
            >
              View
            </Button>
          </Space>
        ),
      },
    ];
  };

  handleSearch = async () => {
    this.setState({ isLoadingTable: true });

    try {
      const {
        dateRangeStart,
        dateRangeEnd,
        selectedMerchantSlugs,
        selectedDspId,
        selectedTrackingEvents,
        timezone,
        filterOption,
        selectedTrackingIds,
      } = this.state;
      let labels: ILabelV2[] = [];

      switch (filterOption) {
        case FilterOption.createdAt:
          labels = await getLabelsByCreatedAtRangeAndSlugs(
            dateRangeStart.format(DATE_FORMAT.SHIP_DATE),
            dateRangeEnd.format(DATE_FORMAT.SHIP_DATE),
            timezone
          );
          break;
        case FilterOption.inCustody:
          labels = await getLabelsForShipDateRangeAndSlugs(
            dateRangeStart.format(DATE_FORMAT.SHIP_DATE),
            dateRangeEnd.format(DATE_FORMAT.SHIP_DATE),
            selectedMerchantSlugs,
            selectedDspId
          );
          break;
        case FilterOption.trackingEvent:
          labels = await getLabelsForTrackingEventStatuses(
            dateRangeStart.format(DATE_FORMAT.SHIP_DATE),
            dateRangeEnd.format(DATE_FORMAT.SHIP_DATE),
            timezone,
            selectedMerchantSlugs,
            selectedTrackingEvents
          );
          break;
        case FilterOption.trackingIds:
          labels = await getLabelsByTrackingIds(selectedTrackingIds);
          break;
        default:
          labels = [];
      }

      this.setState({
        isLoadingTable: false,
        labels,
        filteredLabels: labels,
        filteredFields: {},
        selectedLabels: [],
        labelsDateStart: dateRangeStart.clone(),
        labelsDateEnd: dateRangeEnd.clone(),
      });
    } catch (error) {
      message.error(`Error happened while searching labels for ship date. Error: ${error}`);
      console.error(error);
      this.setState({ isLoadingTable: false });
    }
  };

  handleStatePassedLabelIds = async () => {
    this.setState({ isLoadingTable: true });

    try {
      const labelIds = get(this.props, ['location', 'state', 'labelIds'], []);

      const labels: ILabelV2[] = await Promise.all(
        labelIds.map((labelId: string) => getLabelById(labelId))
      );

      const labelDates = labels.map((label) => label.shipDate).sort();

      this.setState({
        isLoadingTable: false,
        labels,
        filteredLabels: labels,
        filteredFields: {},
        selectedLabels: [],
        labelsDateStart: moment(labelDates[0]),
        labelsDateEnd: moment(labelDates[labelDates.length - 1]),
      });
    } catch (error) {
      message.error(`Error happened while fetching labels by id. Error: ${error}`);
      console.error(error);
      this.setState({ isLoadingTable: false });
    }
  };

  handleTableChange = (_pagination: any, filters: any, _sorter: any, extra: any) => {
    this.setState({
      filteredLabels: extra.currentDataSource,
      filteredFields: filters,
      selectedLabels: [],
    });
  };

  printLabels = async (labels: ILabelV2[]) => {
    this.setState({ isPrintingLabels: true });

    const labelDates = labels.map((label) => label.shipDate).sort();
    const merchantSlugs = uniq(labels.map((label) => label.merchantSlug));

    try {
      const labelIds = labels.map((label) => label.id);
      const result = await FUNCTIONS.printPdfLabels({ labelIds });
      const link = document.createElement('a');
      link.href = result.data;
      link.target = '_blank';
      const fileName = `${labelDates[0]}_${labelDates[labelDates.length - 1]}_${merchantSlugs.join(
        '+'
      )}.pdf`;
      link.download = fileName;
      link.click();
      message.success('Downloaded PDF');
    } catch (err) {
      message.error('Something went wrong. Unable to print labels');
    }

    this.setState({ isPrintingLabels: false });
  };

  downloadLabelsAsCSV = (labels: ILabelV2[]) => {
    try {
      this.setState({ isDownloadingCsv: true });
      exportLabelsCSV(
        'sway_labels.csv',
        labels,
        this.state.dsps,
        () => {
          message.success('Downloaded CSV');
          this.setState({ isDownloadingCsv: false });
        },
        () => {
          message.error('Error downloading csv');
          this.setState({ isDownloadingCsv: false });
        }
      );
    } catch (err) {
      message.error('Something went wrong. Unable to download csv');
      this.setState({ isDownloadingCsv: false });
    }
  };

  setNewShipDate = (shipDate: Moment | null) => {
    this.setState({ rescheduleShipDate: shipDate || moment() });
  };

  setNewLabelsState = (labelState: TrackingEventState | null) => {
    this.setState({ modifyLabelsState: labelState || TrackingEventState.PENDING });
  };

  showRescheduleLabelsModal = () => {
    this.setState({ isRescheduleLabelsModalVisible: true });
  };

  hideRescheduleLabelsModal = () => {
    this.setState({
      isRescheduleLabelsModalVisible: false,
      rescheduleLabelErrors: [],
      rescheduleShipDate: moment(),
    });
  };

  showModifyLabelsStateModal = () => {
    this.setState({ isModifyLabelsStateModalVisible: true });
  };

  hideModifyLabelsStateModal = () => {
    this.setState({
      isModifyLabelsStateModalVisible: false,
      modifyLabelsState: TrackingEventState.PENDING,
    });
  };

  modifyLabelsState = async () => {
    this.setState({ isModifyingLabelsState: true });
    const { modifyLabelsState, selectedLabels } = this.state;
    try {
      await Promise.all(
        selectedLabels.map((label) =>
          FUNCTIONS.updateLabelState({ labelId: getLabelId(label), state: modifyLabelsState })
        )
      );
    } catch (err) {
      console.error(err);
      message.error('Something went wrong while trying to update state for the labels.');
    } finally {
      // reloading table info
      this.handleSearch();
      this.setState({ isModifyingLabelsState: false });
    }
  };

  showAssignToDspModal = () => {
    this.setState({ isAssignToDspModalVisible: true });
  };

  hideAssignToDspModal = () => {
    this.setState({
      isAssignToDspModalVisible: false,
    });
  };

  assignToDsp = async () => {
    const { selectedAssignedDspId, selectedLabels } = this.state;
    if (selectedAssignedDspId === '' || selectedAssignedDspId === undefined) {
      message.error('You have to choose a DSP to assign.');
      return;
    }
    const labelIds = selectedLabels.map(getLabelId);
    this.setState({ isAssigningToDsp: true });
    const batchSize =
      labelIds.length >= MIN_ASSIGN_TO_DSP_BATCH
        ? Math.ceil(labelIds.length / ASSIGN_TO_DSP_BATCH_COUNT)
        : labelIds.length;
    const allLabelIds = chunk(labelIds, batchSize);
    try {
      await Promise.all(
        allLabelIds.map((labelIds: string[]) =>
          FUNCTIONS.assignLabelsToDsp({
            labelIds,
            dspId: selectedAssignedDspId,
          })
        )
      );
      this.hideAssignToDspModal();
      message.success('Labels assigned to DSP');
    } catch (err) {
      console.error(err);
      message.error('Something went wrong while trying to assign labels to DSP.');
    } finally {
      // reloading table info
      this.handleSearch();
      this.setState({ isAssigningToDsp: false });
    }
  };

  handleSearchByLabelId = async (labelId: string) => {
    this.setState({ isLoadingTable: true });

    try {
      const label = await getLabelById(labelId);

      this.setState({
        labels: [label],
        filteredLabels: [label],
        filteredFields: {},
        selectedLabels: [],
        labelsDateStart: moment(label.shipDate),
        labelsDateEnd: moment(label.shipDate),
      });
    } catch (err) {
      message.error(`Something went wrong while trying to get label data. Error: ${err}`);
      console.error(err);
    }

    this.setState({ isLoadingTable: false });
  };

  showEditModal = (label: ILabelV2) => {
    this.setState({ isEditModalVisible: true, selectedLabel: label });
  };

  hideEditModal = () => {
    this.setState({ isEditModalVisible: false });
  };

  showLabelDetailsDrawer = (label: ILabelV2) => {
    this.setState({ isLabelDetailsDrawerVisible: true, selectedLabel: label });
  };

  hideLabelDetailsDrawer = () => {
    this.setState({ isLabelDetailsDrawerVisible: false });
  };

  onLabelSelectionChange = (_selectedRowKeys: any, selectedRows: any) => {
    this.setState({ selectedLabels: selectedRows });
  };

  handleSetCustomDateRange = (range: any) => {
    if (range[0] && range[1]) {
      this.setState({
        dateRangeStart: range[0],
        dateRangeEnd: range[1],
      });
    }
  };

  handleDateChange = (duration: number, unit: unitOfTime.Base) => {
    const { dateRangeStart, dateRangeEnd } = this.state;

    this.setState({
      dateRangeStart: dateRangeStart.clone().add(duration, unit),
      dateRangeEnd: dateRangeEnd.clone().add(duration, unit),
    });
  };

  render() {
    const {
      labels,
      isLoadingTable,
      modifyLabelsState,
      selectedLabel,
      isLabelDetailsDrawerVisible,
      isPrintingLabels,
      isDownloadingCsv,
      isModifyingLabelsState,
      isModifyLabelsStateModalVisible,
      isAssigningToDsp,
      isAssignToDspModalVisible,
      selectedLabels,
      filteredLabels,
      dateRangeStart,
      dateRangeEnd,
    } = this.state;

    const activeLabels = selectedLabels.length ? selectedLabels : filteredLabels;

    const labelStatusMapper: Record<TrackingEventState, number> = {
      [TrackingEventState.PENDING]: 0,
      [TrackingEventState.RECEIVED]: 0,
      [TrackingEventState.IN_TRANSIT]: 0,
      [TrackingEventState.FAILED]: 0,
      [TrackingEventState.DELIVERED]: 0,
      [TrackingEventState.RETURNING_TO_SENDER]: 0,
      [TrackingEventState.CANCELED]: 0,
      [TrackingEventState.DELETED]: 0,
      [TrackingEventState.DELAYED]: 0,
      [TrackingEventState.PROBLEM]: 0,
    };
    const labelMerchantMapper: Record<string, number> = {};

    activeLabels.forEach((l) => {
      if (l.state in labelStatusMapper) {
        labelStatusMapper[l.state] += 1;
      } else {
        labelStatusMapper[l.state] = 1;
      }

      if (l.merchantSlug in labelMerchantMapper) {
        labelMerchantMapper[l.merchantSlug] += 1;
      } else {
        labelMerchantMapper[l.merchantSlug] = 1;
      }
    });

    return (
      <>
        <Row>
          <SearchBySelector onChange={this.onSearchByOptionChange} />

          {this.state.filterOption === FilterOption.createdAt ? (
            <CreatedAtDateSearchByInput
              dateRangeStart={dateRangeStart}
              dateRangeEnd={dateRangeEnd}
              onSelectDateRange={this.handleSetCustomDateRange}
              onSelectTimezone={this.setTimezone}
            ></CreatedAtDateSearchByInput>
          ) : null}

          {this.state.filterOption === FilterOption.inCustody ? (
            <InCustodyDateSearchByInput
              dateRangeStart={dateRangeStart}
              dateRangeEnd={dateRangeEnd}
              onSelectDateRange={this.handleSetCustomDateRange}
              onSelectMerchant={this.setSelectedMerchantSlugs}
              onSelectDsp={this.setSelectedDspId}
            ></InCustodyDateSearchByInput>
          ) : null}

          {this.state.filterOption === FilterOption.trackingEvent ? (
            <TrackingEventSearchByInput
              dateRangeStart={dateRangeStart}
              dateRangeEnd={dateRangeEnd}
              onSelectDateRange={this.handleSetCustomDateRange}
              onSelectTimezone={this.setTimezone}
              onSelectMerchant={this.setSelectedMerchantSlugs}
              onSelectTrackingEvent={this.setSelectedTrackingEvents}
            ></TrackingEventSearchByInput>
          ) : null}

          {this.state.filterOption === FilterOption.trackingIds ? (
            <TrackingIdsSearchByInput
              onSelectTrackingIds={this.setSelectedTrackingIds}
            ></TrackingIdsSearchByInput>
          ) : null}

          {this.state.filterOption === FilterOption.clientDetails ? (
            <ClientDetailsSearchByInput
              onSeletLabel={this.handleSearchByLabelId}
            ></ClientDetailsSearchByInput>
          ) : null}

          <Button
            style={{ margin: '10px' }}
            type="primary"
            onClick={this.handleSearch}
            icon={<FileSearchOutlined />}
          >
            Search
          </Button>
        </Row>
        <Row>
          <Col span={24}>
            {labels.length > 0 && (
              <div
                style={{
                  margin: 20,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <Space style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                  <Statistic
                    style={{ marginRight: '30px' }}
                    title={<span style={{ fontSize: '14px' }}># Labels</span>}
                    valueStyle={{ color: blue.primary, fontSize: '20px' }}
                    value={`${activeLabels.length}`}
                  />

                  <LabelStatsModal title="Labels By Merchant" labelMapper={labelMerchantMapper} />
                  <LabelStatsModal title="Labels By Status" labelMapper={labelStatusMapper} />
                </Space>
                <Space>
                  <Button
                    type="primary"
                    size="middle"
                    loading={isAssigningToDsp}
                    onClick={this.showAssignToDspModal}
                  >
                    Assign to DSP
                  </Button>
                  <Button
                    type="primary"
                    size="middle"
                    loading={isPrintingLabels}
                    icon={<PrinterOutlined />}
                    onClick={() => this.printLabels(activeLabels)}
                  >
                    Print Labels
                  </Button>
                  <Button
                    type="primary"
                    size="middle"
                    icon={<DownloadOutlined />}
                    loading={isDownloadingCsv}
                    onClick={() => this.downloadLabelsAsCSV(activeLabels)}
                  >
                    Download as CSV
                  </Button>
                </Space>
              </div>
            )}
            <Modal
              title="Modify Labels state"
              visible={isModifyLabelsStateModalVisible}
              onCancel={this.hideModifyLabelsStateModal}
              okText="Modify Labels State"
              onOk={this.modifyLabelsState}
              confirmLoading={isModifyingLabelsState}
              style={{
                maxHeight: '80%',
              }}
              width="50%"
            >
              <Space
                align="center"
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                Choose a new state for the selected labels:
                <Select value={modifyLabelsState} onChange={this.setNewLabelsState}>
                  {getTrackingEventStateOptions()}
                </Select>
              </Space>
              <Text
                style={{
                  width: '100%',
                  color: 'red',
                }}
              >
                Please note that this change will affect {selectedLabels.length.toString()} label(s)
              </Text>
            </Modal>
            <Modal
              title="Assign Labels to DSP"
              visible={isAssignToDspModalVisible}
              onCancel={this.hideAssignToDspModal}
              okText="Assign"
              onOk={this.assignToDsp}
              confirmLoading={isAssigningToDsp}
              style={{
                maxHeight: '80%',
              }}
              width="50%"
            >
              <Space
                align="center"
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                Choose a DSP for the selected labels:
                <DspSelector onChange={this.setSelectedAssignedDspId} />
              </Space>
              <Text
                style={{
                  width: '100%',
                  color: 'red',
                }}
              >
                Please note that this change will affect {selectedLabels.length.toString()} label(s)
              </Text>
            </Modal>
            <Table
              size="small"
              pagination={DEFAULT_PAGINATION_CONFIG}
              rowSelection={{
                selectedRowKeys: selectedLabels.map(getLabelId),
                onChange: this.onLabelSelectionChange,
                selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
              }}
              loading={isLoadingTable}
              dataSource={labels}
              rowKey={(label) => label.id}
              columns={this.getLabelTableColumns()}
              onChange={this.handleTableChange}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
        <LabelDetailsDrawer
          label={selectedLabel}
          isVisible={isLabelDetailsDrawerVisible}
          onClose={this.hideLabelDetailsDrawer}
        />
      </>
    );
  }
}

export const Labels = withRouter(LabelsBase);

import React, { useCallback, useState } from 'react';
import { Select, Space, Row, Col } from 'antd';

import { MerchantSlugSelector } from '../MerchantSlugSelector';
import { TimezoneSelector } from '../TimezoneSelector';
import { DateRangeTabs } from './DateRangeTabs';
import { Moment } from 'moment';
import { TrackingEventState } from '@swyft/swyft-common';

interface Props {
  dateRangeStart: Moment;
  dateRangeEnd: Moment;
  onSelectDateRange: (range: any) => void;
  onSelectTimezone: (timezone: string) => void;
  onSelectMerchant: (selectedMerchantSlugs: string[]) => void;
  onSelectTrackingEvent: (trackingEventStates: TrackingEventState[]) => void;
}

export const TrackingEventSearchByInput = (props: Props) => {
  const [selectedEvents, setSelectedEvents] = useState<TrackingEventState[]>([]);

  const handleSelectTrackingEvent = useCallback(
    (value: TrackingEventState[]) => {
      setSelectedEvents(value);
      props.onSelectTrackingEvent(value);
    },
    [selectedEvents]
  );

  const trackingEventsStates = Object.keys(TrackingEventState);

  return (
    <Space style={{ margin: '10px' }}>
      <DateRangeTabs {...props}></DateRangeTabs>
      <TimezoneSelector onChange={props.onSelectTimezone} />
      <Select
        mode="multiple"
        value={selectedEvents}
        placeholder="Status Date Filter"
        onChange={handleSelectTrackingEvent}
        style={{ width: 200 }}
        allowClear={false}
        options={trackingEventsStates.map((event) => {
          return { label: event, value: event };
        })}
      />
      <MerchantSlugSelector onChange={props.onSelectMerchant} />
    </Space>
  );
};
